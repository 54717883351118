import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types'

import Moment from 'react-moment';
import 'moment/locale/es';



import JwPlayer from '../components/JwPlayer'
import ReactPlayer from 'react-player'
import { render } from '@testing-library/react'
import { v1 as uuidv1 } from 'uuid'



const Player = ({playerType, settings, show, image,
    playerRef,
    onReady, onPlay, onPause, onEnded, onCast, onError
}) => {

    const playerId = uuidv1();
    

    useEffect(() => {
        // console.log(playerType, show)
    }, [playerType, show])

    useEffect(() => {
        // console.log(settings)
    }, [settings])


    function renderJwPlayer() {
        let channelId = settings?.channelId
        let mediaId = settings?.mediaId
        // console.debug('player settings', settings)

        return <JwPlayer 
        //ref={ playerRef }
                playerId={playerId}
                mediaType={settings.media_type}
                channelId={channelId}
                mediaId={mediaId}
                // image={`http://assets.viveloonline.com/shows/5eed57a46ba924af375b7178/juevesdetrova-20200626-portada.png`}        
                image={image || show.main_image}
                style={{width: '100%'}}
                show={show}
                customProps={settings.customProps}
                isMuted={settings.muted || false}
                onPlay={onPlay}
                onReady={onReady}
                //onEnded={onEnded}
            />
    }

    function renderGenericPlayer() {
        return <>
        <div className="video-player" 
        //ref={ playerRef }
        >
            <ReactPlayer
                className="react-player"                
                // config={{
                //     vimeo: {
                //         playerOptions: {
                //             controls: false,
                //             loop: true
                //         }
                //     }
                // }}
                //url="https://vimeo.com/417491036" 
                //url="https://vimeo.com/event/133164"
                url={settings.videoUrl}
                played={settings.played}
                loaded={settings.loaded}
                controls={settings.controls}
                playsinline={settings.playsInline}
                playing={settings.playing}
                loop={settings.loop}
                
                // light={playerSettings.light}
                volume={0.8}
                width='100%'
                height='100%'
                onEnded={onEnded}
                onPlay={onPlay}
                onPause={onPause}
                onReady={onReady}
                // onLoad={handleOnLoad}
                // onBuffer={handleOnBuffer}
                // onBufferEnd={handleOnBufferEnd}
                // onStart={handleOnStart}
            />
        </div> 
        </>       
    }

    function render() {
        let player
        console.log(playerType)

        switch(playerType) {
            case 'jwplayer': {
                player = renderJwPlayer()
                break
            }
            case 'generic': {
                player = renderGenericPlayer()
                break
            }
        }

        return player

    }

    return render()
}


export default Player