import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import Cookie from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt, faPlus, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Form, Row, Col, Button, Badge, Modal } from "react-bootstrap";
import CreditCardInput from 'react-credit-card-input';
import { LinkContainer } from "react-router-bootstrap";

import CartSummaryBox from "components/cart/CartSummaryBox"
import LoaderButton from "components/LoaderButton";

import { useCart } from "libs/cartLib";
import { useAnalytics } from "libs/analytics";
import { useFormFields } from "libs/hooksLib";
import { useAppContext } from "libs/contextLib";
import { renderCountryOptions } from "libs/formsLib";
import { onError, Notification } from "libs/errorLib";
// import { useRecaptcha } from "libs/recaptchLib";
import config from 'config';

// import './Loader.scss'

export default function CreditCardForm(props) {
    const { user, ...otherProps } = props

    const [isLoading, setIsLoading] = useState(false)
    const [orderTotal, setOrderTotal] = useState(0)
    const [cart, setCart] = useState(null)
    const [cardUpdated, setCardUpdated] = useState(true)
    const [cardError, setCardError] = useState(false)

    const { trackEvent } = useAnalytics();
    const { getSession, updateCartCounter } = useAppContext();    
    const { getCart, createOrder, updatePaymentInfo } = useCart();    
    
    const [fields, handleFieldChange] = useFormFields({
        ccSaveInfo: true,
        ccFirstname: user.firstname || "",
        ccLastname: user.lastname || "",
        ccEmail: user.email || "",
        ccEmailConfirm: user.email || "",
        ccPhone: user.phone || "",
        ccAddress: user.address || "",
        ccCity: user.city || "",
        ccState: user.state || "",
        ccCountry: user.country || "GT",
        ccZip: user.zip || "",
        ccNit: user.nit || "C/F",
        ccName: "",
        ccNumber: null,    
        ccExpiry: null,
        ccCvv2: null,    
      });

    // const { initRecaptcha, executeRecaptcha } = useRecaptcha();
    

    // useEffect(() => {

    //     // const searchParams = new URLSearchParams(location.search);
    //     // if (searchParams.get('activated') == "1") {
    //     //     //Notification('success','Tu boleto ha sido activado exitosamente')
    //     //     setShowActivationModal(false)        
    //     // }
    
    //     async function onLoad() {
    //         try {           
    //             setIsLoading(true)
    //             session = await getSession()
                                
    //             // if (isAuthenticated) {    
    //             //     console.log(session, show)            
    //             //     const vShow = await loadViewerShow(session.viewer._id, show._id)
    //             // }
    
    //         } catch (e) {
    //             onError(e);
    //         } finally {
    //             setIsLoading(false)
    //         }
    //     }
    
    //     onLoad();
    // }, []);  
    useEffect(() => {        
        onLoad()         
        
    }, [cart]);

    async function onLoad() {
        let cart
        try {
            cart = await getCart()
            setCart(cart || {})
        } catch (e) {
            // no cart
        }
    }

    useEffect(() => {
        // initRecaptcha()
    }, []);

    async function processUpdatePaymentInfo(payment_type, payment_info) {
        const session = await getSession()

        const payload = {
            // user_uid: session.user._id,
            payment_type: payment_type,
            currency: cart?.currency || 'GTQ',
            payment_info: payment_info,                
        }
        const purchase = await updatePaymentInfo(payload)
        setCart(updateCartCounter())

        // const purchase = await API.post("vivelo", `/viewers/orders/`, {
        //     body: {
        //         user_uid: session.user._id,
        //         payment_type: payment_type,
        //         currency: 'GTQ',
        //         payment_info: payment_info,                
        //     }
        // });
        
        return purchase
    }

    async function processPayment(payment_type, payment_info) {
        const session = await getSession()

        const order = {
            user_uid: session.user._id,
            payment_type: payment_type,
            currency: cart?.currency || 'GTQ',
            payment_info: payment_info,                
        }
        const purchase = await createOrder(order)

        // const purchase = await API.post("vivelo", `/viewers/orders/`, {
        //     body: {
        //         user_uid: session.user._id,
        //         payment_type: payment_type,
        //         currency: 'GTQ',
        //         payment_info: payment_info,                
        //     }
        // });
        
        return purchase
    }



    /* VALIDATORS */
    function validateCreditCardForm() {
        //return fields.purchaseCode.length > 0 && fields.purchaseCodeTicket;
        return fields.ccFirstname.length > 0 
            && fields.ccLastname.length > 0
            && fields.ccEmail.length > 0
            && fields.ccEmail == fields.ccEmailConfirm
            && fields.ccPhone.length > 0 
            && fields.ccAddress.length > 0 
            && fields.ccCity.length > 0 
            && fields.ccState.length > 0 
            && fields.ccCountry.length > 0 
            && (
                (['GT'].includes(fields.ccCountry) && fields.ccNit.length > 0) ||
                (['US','CA'].includes(fields.ccCountry) && fields.ccZip.length > 0) ||
                (!['GT','US','CA'].includes(fields.ccCountry))
            )
            && fields.ccName.length > 0 
            && fields.ccNumber            
            && fields.ccExpiry
            && fields.ccCvv2
            && !cardError;
            // && orderTotal > 0;

    }

    /* HANDLERS */
    function handleChangeOrderButton() {
        trackEvent({
            action: "Clicked ChangeOrder Button",
            category: "PurchaseFunnel"
        });
    }    
    
    function handleCardInputChange(event) {
        switch(event.target.id) {
            case 'card-number': {
                event.target.id = 'ccNumber'
                break;
            }
            case 'card-expiry': {
                event.target.id = 'ccExpiry'
                break;
            }
            case 'cvc': {
                event.target.id = 'ccCvv2'
                break;
            }

        }
        handleFieldChange(event)
        setCardUpdated(false)
        setCardError(false)
    }



    async function handleCreditCardUpdate(event) {
        event.preventDefault();
        setIsLoading(true);
        trackEvent({
            action: "Clicked CreditCard Update Button",
            category: "PurchaseFunnel"
        });
        
        try {
            // const captchaResult = await executeRecaptcha('purchase')
            // if (!captchaResult.success) {
            //     Notification('error', 'Error de verificación.  Por favor vuelve a intentar.')                
            //     setIsLoading(false);
            //     return false
            // }

            const payment_info = {
                    payment_type: 'CREDIT_CARD',
                    cc_bin: fields.ccNumber.split(" ").join("").substring(0,6),                    
            }
                        
            const result = await processUpdatePaymentInfo(payment_info.payment_type, payment_info)
            if (result) {
                setCardUpdated(true)
                return true
            } else {
                setCardUpdated(false)
                Notification('error', 'No fue posible actualizar el carrito', null, 'top')
                setIsLoading(false);
                return false
            }        
            
        } catch(error) {
            setCardUpdated(false)
            // console.log(error, error.response, error.status, error.data, error.errors)            
            Notification('error', 'No fue posible actualizar los datos', null, 'top')
            setIsLoading(false);
            return false
        } finally {
            setIsLoading(false);
        }

        
    }  

    async function handleSubmitCreditCard(event) {    
        event.preventDefault();        
        setIsLoading(true);
        trackEvent({
            action: "Clicked Pay Button",
            category: "PurchaseFunnel"
        });
        
        try {
            // const captchaResult = await executeRecaptcha('purchase')
            // if (!captchaResult.success) {
            //     Notification('error', 'Error de verificación.  Por favor vuelve a intentar.')                
            //     setIsLoading(false);
            //     return false
            // }

            const payment_info = {
                    firstname: fields.ccFirstname,
                    lastname: fields.ccLastname,
                    email: fields.ccEmail,
                    phone: fields.ccPhone,
                    address: fields.ccAddress,
                    city: fields.ccCity,
                    state: fields.ccState,
                    country: fields.ccCountry,
                    nit: fields.ccNit || "C/F",
                    zip: fields.ccZip || "",
                    cc_name: fields.ccName,
                    cc_number: fields.ccNumber.split(" ").join(""),
                    cc_exp: fields.ccExpiry.split(" ").join(""),
                    cc_cvv2: fields.ccCvv2,
                    currency: cart?.currency || 'GTQ',
                    total: orderTotal,
                    sessionId: Cookie.get('sessionID')
            }
                        
            const purchase = await processPayment('CREDIT_CARD', payment_info)
            if (purchase) {
                // console.log(purchase)
                //setIsLoading(false);
                payment_info.save_info = fields.ccSaveInfo
                props.onSuccess && props.onSuccess(payment_info, purchase)
                // history.push(`/orders/${purchase.id}`)
                return true
            } else {
                Notification('error', 'No fue posible realizar la compra', null, 'top')
                props.onFailure && props.onFailure(purchase)
                setIsLoading(false);
                return false
            }        
            
        } catch(error) {
            // console.log(error, error.response, error.status, error.data, error.errors)
            if (error.response && error.response.status === 402) {
                switch(error.response.data.code) {
                    case 31: {
                        Notification('error', 'La transacción no pudo ser completada. Tarjeta no soportada por SWITCH', null, 'top')
                        break;
                    }
                    case 58: {
                        Notification('error', 'La transacción no pudo ser completada, por favor intenta nuevamente. ', null, 'top')
                        break;
                    }
                    case 203: {
                        Notification('error', 'Transacción rechazada por el banco emisor. Por favor revisa los datos ingresados o contacta a tu banco para autorizar la transacción', null, 'top')
                        break;
                    }
                    case 481: {
                        Notification('error', 'Transacción rechazada por el sistema antifraude. Por favor revisa los datos e intenta de nuevo en 24 horas.', null, 'top')                
                        break;
                    }
                    case 5001: {
                        Notification('error', error.response.data.message, null, 'top')
                        break;
                    }
                    default: {
                        Notification('error', 'La transacción ha sido denegada.  Revisa que todos los datos esten correctos.', null, 'top')                
                        break;
                    }
                }                
                setIsLoading(false);
            } else {
                Notification('error', 'No fue posible realizar la compra', null, 'top')
                setIsLoading(false);
            }
            return false
        } finally {
            
        }

        
    }  

    function renderDeviceFingerprintPixel() {
        const ordId = config.fingerprint.ordId;
        const x_login = config.fingerprint.login;
        const sessionId = Cookie.get('sessionID');

        return <><p style={{background:`url('https://h.online-metrix.net/fp/clear.png?org_id=$${ordId}&session_id=${x_login}${sessionId}&m=1')`}}></p>
            <img src={`https://h.online-metrix.net/fp/clear.png?org_id=$${ordId}&session_id=${x_login}${sessionId}&m=2`} alt=""></img></>
    }

    function renderCreditCardForm() {
        return <Form> 
             {/* onSubmit={handleSubmitCreditCard}> */}
            <Row>
                <Col md="6">                    
                    <h2>Datos personales</h2>
                    <Form.Row>
                        <Form.Group as={Col} controlId="ccSaveInfo">                        
                        <Form.Check type="checkbox" label="Guardar los datos en mi perfil" checked={fields.ccSaveInfo} onChange={handleFieldChange} />                        
                        </Form.Group>
                        
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="ccFirstname">
                        <Form.Label>Nombre*</Form.Label>
                        <Form.Control required placeholder="ej: José"  value={fields.ccFirstname} onChange={handleFieldChange} className={fields.ccFirstname == "" ? "field-error" : "field-ok"} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="ccLastname">
                        <Form.Label>Apellido*</Form.Label>
                        <Form.Control placeholder="ej: Pérez"  value={fields.ccLastname} onChange={handleFieldChange}  className={fields.ccLastname == "" ? "field-error" : "field-ok"} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="ccEmail">
                        <Form.Label>Email*</Form.Label>
                        <Form.Control type="email" placeholder="" value={fields.ccEmail} onChange={handleFieldChange}  className={fields.ccEmail == "" ? "field-error" : "field-ok"} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="ccEmailConfirm">
                        <Form.Label>Confirmar Email*</Form.Label>
                        <Form.Control type="email" placeholder="" value={fields.ccEmailConfirm} onChange={handleFieldChange}  className={fields.ccEmailConfirm != fields.ccEmail ? "field-error" : "field-ok"} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        
                        <Form.Group as={Col} controlId="ccPhone">
                        <Form.Label>Telefono*</Form.Label>
                        <Form.Control type="tel" placeholder="" value={fields.ccPhone} onChange={handleFieldChange}  className={fields.ccPhone == "" ? "field-error" : "field-ok"} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="ccAddress">
                            <Form.Label>Direcci&oacute;n*</Form.Label>
                            <Form.Control value={fields.ccAddress} onChange={handleFieldChange}  className={fields.ccAddress == "" ? "field-error" : "field-ok"} />
                        </Form.Group>
                    </Form.Row>
                    
                        

                    <Form.Row>
                        <Form.Group as={Col} controlId="ccCity">
                        <Form.Label>Municipio/Ciudad*</Form.Label>
                        <Form.Control value={fields.ccCity} onChange={handleFieldChange}  className={fields.ccCity == "" ? "field-error" : "field-ok"} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="ccState">
                        <Form.Label>Departamento/Estado*</Form.Label>
                        <Form.Control value={fields.ccState} onChange={handleFieldChange}  className={fields.ccState == "" ? "field-error" : "field-ok"} />
                        </Form.Group> 

                        <Form.Group as={Col} controlId="ccCountry">
                        <Form.Label>Pa&iacute;s*</Form.Label>
                        <Form.Control as="select" value={fields.ccCountry} onChange={handleFieldChange} >                            
                            {renderCountryOptions()}
                        </Form.Control>
                        </Form.Group>                       
                    </Form.Row>

                    { ['GT'].includes(fields.ccCountry) && <Form.Row>
                        <Form.Group as={Col} controlId="ccNit">
                        <Form.Label>NIT <span style={{fontSize: '1rem'}}>("C/F" si no tienes NIT)</span></Form.Label>
                        <Form.Control value={fields.ccNit} onChange={handleFieldChange}  className={fields.ccNit == "" ? "field-error" : "field-ok"} />
                        </Form.Group>            
                    </Form.Row> }

                    { ['US','CA'].includes(fields.ccCountry) && <Form.Row>
                        <Form.Group as={Col} controlId="ccZip">
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control value={fields.ccZip} onChange={handleFieldChange}  className={fields.ccZip == "" ? "field-error" : "field-ok"} />
                        </Form.Group>            
                    </Form.Row> }

                </Col>
                <Col md="6">
                    <h2>Datos de tarjeta</h2>
                    <Form.Row>
                    
                        <Form.Group controlId="ccName">
                        <Form.Label>Nombre*</Form.Label>
                        <Form.Control autoComplete="off" placeholder="ej: José Perez" value={fields.ccName} onChange={handleFieldChange} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group>                    
                        <Form.Label style={{width:'100%'}}>Informaci&oacute;n de pago*</Form.Label>
                        <CreditCardInput
                            onError={() => setCardError(true)}
                            cardNumberInputProps={{ value: fields.ccNumber, onChange: handleCardInputChange }}
                            cardExpiryInputProps={{ value: fields.ccExpiry, onChange: handleCardInputChange }}
                            cardCVCInputProps={{ type: 'password', value: fields.ccCvv2, onChange: handleCardInputChange }}
                            fieldClassName="form-control vivelo-field" inputClassName="vivelo-input"
                            customTextLabels={{
                                invalidCardNumber: 'El número de la tarjeta es inválido',
                                expiryError: {
                                invalidExpiryDate: 'La fecha de expiración es inválida',
                                monthOutOfRange: 'El mes de expiración debe estar entre 01 y 12',
                                yearOutOfRange: 'El año de expiración no puede estar en el pasado',
                                dateOutOfRange: 'La fecha de expiración no puede estar en el pasado'
                                },
                                invalidCvc: 'El código de seguridad es inválido',
                                invalidZipCode: 'El código postal es inválido',
                                cardNumberPlaceholder: 'Número de tarjeta',
                                expiryPlaceholder: 'MM/AA',
                                cvcPlaceholder: 'COD',
                                zipPlaceholder: 'C.P.'
                            }}
                            />
                        </Form.Group>
                    </Form.Row>
                    {/* { !cardUpdated && 
                    <Form.Row>
                        <LoaderButton
                            block
                            type="button"
                            size="lg"
                            isLoading={isLoading}
                            disabled={!validateCreditCardForm()}
                            variant={validateCreditCardForm() ? 'primary' : 'secondary'}
                            onClick={handleCreditCardUpdate}
                        >
                        Continuar al pago ${cardError}
                        </LoaderButton>
                    </Form.Row> }

                    { cardUpdated && <> */}
                        <Form.Row>
                            {/* {props.cartSummary()} */}
                            <CartSummaryBox readOnly={true} totalsOnly={true} />
                        </Form.Row>        
                        
                        <Form.Row>
                            <LoaderButton
                                block
                                type="button"
                                size="lg"
                                isLoading={isLoading}
                                disabled={!validateCreditCardForm()}
                                variant={validateCreditCardForm() ? 'primary' : 'secondary'}                            
                                onClick={handleSubmitCreditCard}
                            >
                            Pagar   
                            </LoaderButton>                        
                            <div className="note" style={{fontFamily: 'Arial'}}><FontAwesomeIcon icon={faInfoCircle} /> Al confirmar el pago, aceptas los términos y condiciones de QPayPro.</div>
                        </Form.Row>

                        <Form.Row>
                            <LinkContainer to="/" style={{width:'100%', marginTop: 20}} >
                                <Button variant="light" size="sm" style={{width:'100%'}} >Modificar órden</Button>
                            </LinkContainer>
                        </Form.Row>
                        
                        <Form.Row style={{'text-align:':'center', 'border-top': 'solid 1px #0191e2', 'margin-top': 20, 'paddingTop': 20}}>
                            <img src="/img/branding-QPayPro-invert-muestra.png" alt="QPayPro" style={{width:300, 'margin-left':'auto', 'margin-right':'auto'}} />
                        </Form.Row>
                        {renderDeviceFingerprintPixel()}
                    {/* </>} */}
                    
                </Col>
            </Row>                

                
            </Form>       
    }

    return renderCreditCardForm()
}