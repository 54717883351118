import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
// import { Container } from "react-bootstrap";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import NotFound from "./containers/NotFound";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import LoginOrSignup from "./containers/LoginOrSignup";
import NewShow from "./containers/NewShow";
// import Show from "./containers/Show";
import Show from "./components/shows/ShowDefaultLayout";
import Order from "./containers/Order";
import Activate from "./containers/Activate";
import ShowPayment from "./containers/ShowPayment";
import ShowView from "./containers/ShowView";
import ShowViewFullscreen from "./containers/ShowViewFullscreen";
import ShowPlayer from "./containers/ShowPlayer";
import ShowMobileChat from "./containers/ShowMobileChat";
import ShowsList from "./containers/ShowsList";
import Help from "./containers/Help";
import Cookies from "./containers/Cookies";
import PasswordRequest from "./containers/PasswordRequest";
import PasswordReset from "./containers/PasswordReset";
import EmailVerify from "./containers/EmailVerify";
import User from "./containers/User";
import UserOrderList from "./containers/UserOrderList";
import UserShowList from "./containers/UserShowList";
import CartSummary from "containers/CartSummary";
import CartCheckout from "containers/CartCheckout";
import CartRegistration from "containers/CartRegistration";
import ShowQuickBuy from "containers/ShowQuickBuy";
import ShowQuickBuySuccess from "containers/ShowQuickBuySuccess";

import ChatTest from "./containers/ChatTest";
import ChatExtract from "./containers/ChatExtract";
import JwPlayerTest from "./containers/JwPlayerTest";

import IndiaMain from "sites/festivaldecineindia2020/Main";
import IndiaShow from "sites/festivaldecineindia2020/Show";

import InvencibleShow from "sites/soyinvencible/Show";
import InvencibleShowPlayer from "sites/soyinvencible/ShowPlayer";

import ConvivioGalloShow from "sites/conviviogallo/Show";
import ConvivioGalloShowPlayer from "sites/conviviogallo/ShowPlayer";
import ConvivioDoradaShow from "sites/conviviodoradaice/Show";
import ConvivioDoradaShowPlayer from "sites/conviviodoradaice/ShowPlayer";

import FestivalComediaMain from "sites/festivalcomedia2020/Main";

import CarasDelAmorMain from "sites/carasdelamor/Main";

import AluxNavidad2020Main from "sites/aluxnavidad2020/Main";
import AluxNavidad2020ShowPlayer from "sites/aluxnavidad2020/ShowPlayer";

import TitanesSummit2021Main from "sites/titanessummit2021/Main";
import TitanesSummit2021ShowPlayer from "sites/titanessummit2021/ShowPlayer";

import Fafidess2021Main from "sites/fafidess2021/Main";
import Fafidess2021ShowPlayer from "sites/fafidess2021/ShowPlayer";

import Beatles2021ShowPlayer from "sites/beatles2021/ShowPlayer";

import GuatemetalMain from "sites/guatemetal/Main";

import GarraJaguarMain from "sites/garrajaguar/Main";

import LeyendasGtMain from "sites/leyendasgt/Main";

import JandirMain from "sites/jandir/Main";
import JandirVolarMain from "sites/jandirvolar/Main";

import MalasIntencionesMain from "sites/malasintenciones/Main";

import India2021Main from "sites/festivaldecineindia2021/Main";
import India2021Show from "sites/festivaldecineindia2021/Show";

import MarimbasPlayer from "sites/marimbasporguate/ShowPlayer";
import Marimbas2Player from "sites/marimbasporguate2024/ShowPlayer";

import Teleton2021Main from "sites/teleton2021/Main";

import India2023Main from "sites/semanadecineindia2023/Main";
import India2023Show from "sites/semanadecineindia2023/Show";

import CapitanElectricoMain from "sites/capitanelectrico/Main";

import ViernesVerdeMain from "sites/viernesverde30/Main";
import ViernesVerdeXelaMain from "sites/viernesverde30xela/Main";

import DespuesDelSilencioMain from "sites/despuesdelsilencio/Main";
import AstroDeOroMain from "sites/astrodeoro/Main";

import SeguridadVialMain from "sites/seguridadvial/Main";
import SeguridadVialPlayer from "sites/seguridadvial/ShowPlayer";



export default function Routes() {
    const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);        
    //     let code = searchParams.get('code')
    //     if (code) {
    //         code = code.toUpperCase()
    //     }          

  return (
    <Switch>
        <Route exact path="/">
            <div><Home /></div>
        </Route>
        <Route exact path="/shows">
            <Redirect to="/" />
        </Route>
        <UnauthenticatedRoute exact path="/signup">            
            <div><Signup /></div>
        </UnauthenticatedRoute>
        {/* <UnauthenticatedRoute exact path="/login">
            <div><Login /></div>
        </UnauthenticatedRoute> */}
        <UnauthenticatedRoute exact path="/login">
            <div><LoginOrSignup /></div>
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/password/recover">
            <div><PasswordRequest /></div>
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/password/reset/:token">
            <div><PasswordReset /></div>
        </UnauthenticatedRoute>
        <Route exact path="/email/verify/:token">
            <div><EmailVerify /></div>
        </Route>

        {/* USER */}
        <AuthenticatedRoute exact path="/user">
            <div><User /></div>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/user/orders">
            <div><UserOrderList /></div>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/user/shows">
            <div><UserShowList /></div>
        </AuthenticatedRoute>

        {/* Festival Cine India 2020 */}
        <Route exact path="/shows/5f9a3f8663e3f8c66ab48c98">            
            <Redirect to="/festivalcineindia2020" />
        </Route>
        <Route exact path="/shows/5f9a3f9763e3f8c66ab48c9c">            
            <Redirect to="/festivalcineindia2020/akira" />
        </Route>
        <Route exact path="/shows/5f9a3fa763e3f8c66ab48ca0">            
            <Redirect to="/festivalcineindia2020/rustom" />
        </Route>
        <Route exact path="/shows/5f9a3fb663e3f8c66ab48ca4">            
            <Redirect to="/festivalcineindia2020/hindimedium" />
        </Route>
        <Route exact path="/shows/5f9a6b86e12caeedf70448d3">            
            <Redirect to="/festivalcineindia2020/kika" />
        </Route>
        <Route exact path="/shows/5f9a6b91e12caeedf70448d7">            
            <Redirect to="/festivalcineindia2020/bahubali" />
        </Route>
        <Route exact path="/shows/5f9a6b9be12caeedf70448db">            
            <Redirect to="/festivalcineindia2020/bahubali2" />
        </Route>
        <Route exact path="/shows/5f9cf71b26c0a1d986539540">            
            <Redirect to="/festivalcineindia2020/neerja" />
        </Route>

         {/* Festival Cine India 2021 */}
         <Route exact path="/shows/616b57587dcb600f4de4bb3d">            
            <Redirect to="/festivalcineindia2021" />
        </Route>
        <Route exact path="/shows/616b590e7dcb600f4de4bb40">            
            <Redirect to="/festivalcineindia2021/akira" />
        </Route>
        <Route exact path="/shows/616b590e7dcb600f4de4bb42">            
            <Redirect to="/festivalcineindia2021/rustom" />
        </Route>
        <Route exact path="/shows/616b590e7dcb600f4de4bb44">            
            <Redirect to="/festivalcineindia2021/hindimedium" />
        </Route>
        <Route exact path="/shows/616b590f7dcb600f4de4bb46">            
            <Redirect to="/festivalcineindia2021/kika" />
        </Route>
        <Route exact path="/shows/616b590f7dcb600f4de4bb48">            
            <Redirect to="/festivalcineindia2021/bahubali" />
        </Route>
        <Route exact path="/shows/616b590f7dcb600f4de4bb4a">            
            <Redirect to="/festivalcineindia2021/bahubali2" />
        </Route>
        <Route exact path="/shows/616b59107dcb600f4de4bb4c">            
            <Redirect to="/festivalcineindia2021/neerja" />
        </Route>
        <Route exact path="/shows/616c8aa57dcb600f4de4bb50">            
            <Redirect to="/festivalcineindia2021/queen" />
        </Route>

        {/* Soy Invencible */}
        <Route exact path="/shows/5fabf2947094a9f522057ae3">            
            <Redirect to="/soyinvencible" />
        </Route>
        <Route exact path="/shows/5fabf2947094a9f522057ae3/full">            
            <Redirect to="/soyinvencible/evento" />
        </Route>

        {/* Convivio Gallo */}
        <Route exact path="/shows/5fbeee3974201ea1f0065132">            
            <ConvivioGalloShow id="5fbeee3974201ea1f0065132" />
        </Route>
        <Route exact path="/shows/5fbeee3974201ea1f0065132/full">            
            <Redirect to="/conviviogallo/20201128" />
        </Route>
        {/* Convivio Dorada Ice */}
        <Route exact path="/shows/5fd1a3b32d682cbc40598f80">            
            <ConvivioGalloShow id="5fd1a3b32d682cbc40598f80" />
        </Route>
        <Route exact path="/shows/5fd1a3b32d682cbc40598f80/full">            
            <Redirect to="/conviviodoradaice/player" />
        </Route>

        {/* Festival Comedia Guate */}        
        <Route exact path="/shows/5fc862ce3ee3efe594519a7b">
            <Redirect to="/festivalcomediaguate" />
        </Route>

        {/* Las Caras del Amor */}        
        <Route exact path="/shows/5fc968b606ea49f23c9327db">
            <Redirect to="/lascarasdelamor" />
        </Route>

        {/* Alux Navidad 2020 */}        
        <Route exact path="/shows/5fd97dc4efef01b70368a538">
            <Redirect to="/aluxnavidad2020" />
        </Route>
        <Route exact path="/shows/5fd97dc4efef01b70368a538/full">
            <Redirect to="/aluxnavidad2020/player" />
        </Route>

        {/* Titanes Summit 2021 */}        
        <Route exact path="/shows/6005e963eaf3235389ee7b20">
            <Redirect to="/titanessummit2021" />
        </Route>
        <Route exact path="/shows/6005e963eaf3235389ee7b20/full">
            <Redirect to="/titanessummit2021/player" />
        </Route>

        {/* Fafidess 2021 */}        
        <Route exact path="/shows/609339cb6f75e09d25f4d53d">
            <Redirect to="/fafidess2021" />
        </Route>
        <Route exact path="/shows/609339cb6f75e09d25f4d53d/full">
            <Redirect to={`/fafidess2021/player/${location.search}`} />
        </Route>
        <Route exact path="/shows/609339cb6f75e09d25f4d53d/player">
            <Redirect to={`/fafidess2021/player/${location.search}`} />
            {/* <Fafidess2021ShowPlayer id="609339cb6f75e09d25f4d53d" /> */}
        </Route>

        {/* Guatemetal */}
        <Route exact path="/shows/6130639821324e63429bcfa9">
            <Redirect to="/guatemetal" />
        </Route>
        <Route exact path="/shows/610f0c1436f3b31165a4647c">
            <Redirect to="/guatemetal" />
        </Route>
        <Route exact path="/shows/60f6497b3522edf87dcd7847">
            <Redirect to="/guatemetal" />
        </Route>
        <Route exact path="/shows/60d0e056aaef23e810ffcb5a">
            <Redirect to="/guatemetal" />
        </Route>
        <Route exact path="/shows/60a5ed2529ca4d244669f9df">
            <Redirect to="/guatemetal" />
        </Route>
        <Route exact path="/shows/611c9e5cb3ec150234f69bc4">
            <Redirect to="/guatemetal" />
        </Route>
        <Route exact path="/shows/6132ce2be5a2a066808d179f">
            <Redirect to="/guatemetal" />
        </Route>
        <Route exact path="/shows/6132ce30e5a2a066808d17a0">
            <Redirect to="/guatemetal" />
        </Route>


        {/* Beatles 2021 */}        
        <Route exact path="/shows/608f26b10a8a890f8c8ca88d/full">            
            <Beatles2021ShowPlayer id="608f26b10a8a890f8c8ca88d" />
        </Route>
        <Route exact path="/shows/608f26b10a8a890f8c8ca88d/player">
            <Beatles2021ShowPlayer id="608f26b10a8a890f8c8ca88d" />
        </Route>


        {/* Garra Jaguar */}
        <Route exact path="/shows/61394007e60268396c513e2f">
            <GarraJaguarMain id="61394007e60268396c513e2f" />
        </Route>
        <Route exact path="/shows/60c1046f324e3619900f9282">
            <Redirect to="/shows/61394007e60268396c513e2f" />
        </Route>

        {/* Leyendas gt */}
        {/* <Route exact path="/shows/614a661c49525af84cdd0bd5">
            <Redirect to="/recorridodeleyendasgt" />
        </Route>
        <Route exact path="/shows/614a666649525af84cdd0bd9">
            <Redirect to="/recorridodeleyendasgt" />
        </Route>
        <Route exact path="/shows/614a666949525af84cdd0bdd">
            <Redirect to="/recorridodeleyendasgt" />
        </Route>
        <Route exact path="/shows/614a666c49525af84cdd0be1">
            <Redirect to="/recorridodeleyendasgt" />
        </Route>
        <Route exact path="/shows/614a666e49525af84cdd0be5">
            <Redirect to="/recorridodeleyendasgt" />
        </Route>
        <Route exact path="/shows/614a667149525af84cdd0be9">
            <Redirect to="/recorridodeleyendasgt" />
        </Route>
        <Route exact path="/shows/614a667349525af84cdd0bed">
            <Redirect to="/recorridodeleyendasgt" />
        </Route>
        <Route exact path="/shows/614a667649525af84cdd0bf1">
            <Redirect to="/recorridodeleyendasgt" />
        </Route>
        <Route exact path="/shows/614a667849525af84cdd0bf5">
            <Redirect to="/recorridodeleyendasgt" />
        </Route> */}
        <Route exact path="/shows/650a29fd7d155b8b8f3e39ce">
            <Redirect to="/recorridodeleyendasgt" />
        </Route>

        {/* Jandir */}
        <Route exact path="/shows/614e1d380ffb10805d96f43f">
            <Redirect to="/jandirrodriguez" />
        </Route>
        <Route exact path="/shows/621a4b43138d4b20ad89e9fc">
            <Redirect to="/jandirvolar" />
        </Route>

        {/* Malas Intenciones */}
        <Route exact path="/shows/61665333c58a1f2c513b199b">
            <Redirect to="/malasintenciones" />
        </Route>

        {/* Marimbas */}
        <Route exact path="/shows/61b2433e97f533a5758e1bef">
            <Redirect to="/ensamble-de-marimbas" />
        </Route>
        <Route exact path="/shows/669beda7e31bd87128c191b7">
            <Redirect to="/festival-de-marimbas" />
        </Route>

        {/* Teleton 2021 */}
        <Route exact path="/shows/61ba599d23b9823169747054">
            <Redirect to="/teleton2021" />
        </Route>

        {/* Semana Cine India 2023 */}
        <Route exact path="/shows/63c35adcadbdfc4fbf4804b5">            
            <Redirect to="/semanacineindia2023" />
        </Route>
        <Route exact path="/shows/63c363d0adbdfc4fbf480520">            
            <Redirect to="/semanacineindia2023/ankhon_dekhi" />
        </Route>
        <Route exact path="/shows/63c363d0adbdfc4fbf480523">            
            <Redirect to="/semanacineindia2023/dedh_ishqiya" />
        </Route>
        <Route exact path="/shows/63c363d0adbdfc4fbf480526">            
            <Redirect to="/semanacineindia2023/john_day" />
        </Route>
        <Route exact path="/shows/63c363d0adbdfc4fbf480529">            
            <Redirect to="/semanacineindia2023/zed_plus" />
        </Route>
        <Route exact path="/shows/63c363d0adbdfc4fbf48052c">            
            <Redirect to="/semanacineindia2023/8x10_tasveer" />
        </Route>
        <Route exact path="/shows/63c363d0adbdfc4fbf48052f">            
            <Redirect to="/semanacineindia2023/im_24" />
        </Route>
        <Route exact path="/shows/63c363d0adbdfc4fbf480532">            
            <Redirect to="/semanacineindia2023/sharafat_gayi_tel_lene" />
        </Route>

        {/* Capitan Electrico */}
        <Route exact path="/shows/63f2cda973a39290ad41baa3">            
            <Redirect to="/capitan.electrico" />
        </Route>

        {/* Viernes Verde */}
        <Route exact path="/shows/642606dfcc03cb3bd09d3126">
            <Redirect to="/viernesverde30" />
        </Route>
        <Route exact path="/shows/651e0aaf994fcb4727dea0dd">
            <Redirect to="/viernesverde30occidente" />
        </Route>

        {/* Despues del Silencio */}
        <Route exact path="/shows/656ec4c91679d7d6e4b2fe23">
            <Redirect to="/despuesdelsilencio" />
        </Route>

        {/* Astro De Oro */}
        <Route exact path="/shows/6617698a5bf02a8759ac10f3">
            <Redirect to="/astrodeoro" />
        </Route>

        {/* Seguridad Vial */}
        <Route exact path="/shows/6635135aa3c41ba544676465">
            <Redirect to="/seminario-seguridad-vial" />
        </Route>
        <Route  path="/shows/6635135aa3c41ba544676465/player">
            <Redirect to={`/seminario-seguridad-vial/player${location.search}`} />
        </Route>
        



        <Route exact path="/shows/new">
            <div><NewShow /></div>
        </Route>
        <Route exact path="/shows/past">
            <div><ShowsList /></div>
        </Route>
        <Route exact path="/shows/:id">
            <div><Show /></div>
        </Route>
        <Route exact path="/shows/:id/play">
            <div fluid><ShowView /></div>
        </Route>
        <Route exact path="/shows/:id/full">
            <ShowViewFullscreen />
        </Route>
        <Route exact path="/shows/:id/player/">
            <ShowPlayer />
        </Route>
        <Route exact path="/shows/:id/chat">
            <ShowMobileChat />
        </Route>
        <Route exact path="/shows/:id/payment">
            <div><ShowPayment /></div>
        </Route>        
        <Route exact path="/shows/:id/quicklink">
            <div><ShowQuickBuy /></div>
        </Route>
        <Route exact path="/shows/:showId/quicklink/success/:id">
            <div><ShowQuickBuySuccess /></div>
        </Route>        
        <Route exact path="/cart">
            <div><CartSummary /></div>
        </Route>
        <AuthenticatedRoute exact path="/cart/checkout">
            <div><CartCheckout /></div>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/cart/register">
            <div><CartRegistration /></div>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/orders/:id">''
            <div><Order /></div>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/activate">
            <div><Activate /></div>
        </AuthenticatedRoute>

        <Route exact path="/help">
            <div><Help /></div>
        </Route>
        <Route exact path="/cookies">
            <div><Cookies /></div>
        </Route>
        <Route exact path="/chat">
            <div><ChatTest /></div>
        </Route>
        <Route exact path="/player">
            <div><JwPlayerTest /></div>
        </Route>
        <Route exact path="/chatextract">
            <div><ChatExtract /></div>
        </Route>

        {/* Cine de la India */}
        <Route exact path="/festivalcineindia2020">
            <IndiaMain id="5f9a3f8663e3f8c66ab48c98" />
        </Route>
        <Route exact path="/festivalcineindia2020/akira">
            <IndiaShow id="5f9a3f9763e3f8c66ab48c9c" />
        </Route>        
        <Route exact path="/festivalcineindia2020/rustom">
            <IndiaShow id="5f9a3fa763e3f8c66ab48ca0" />
        </Route>
        <Route exact path="/festivalcineindia2020/kika">
            <IndiaShow id="5f9a6b86e12caeedf70448d3" />
        </Route>
        <Route exact path="/festivalcineindia2020/hindimedium">
            <IndiaShow id="5f9a3fb663e3f8c66ab48ca4" />
        </Route>
        <Route exact path="/festivalcineindia2020/bahubali">
            <IndiaShow id="5f9a6b91e12caeedf70448d7" />
        </Route>
        <Route exact path="/festivalcineindia2020/bahubali2">
            <IndiaShow id="5f9a6b9be12caeedf70448db" />
        </Route>
        <Route exact path="/festivalcineindia2020/neerja">
            <IndiaShow id="5f9cf71b26c0a1d986539540" />
        </Route>

        {/* Cine de la India 2021 */}
        <Route exact path="/festivalcineindia2021">
            <India2021Main id="616b57587dcb600f4de4bb3d" />
        </Route>
        <Route exact path="/festivalcineindia2021/akira">
            <India2021Show id="616b590e7dcb600f4de4bb40" />
        </Route>        
        <Route exact path="/festivalcineindia2021/rustom">
            <India2021Show id="616b590e7dcb600f4de4bb42" />
        </Route>
        <Route exact path="/festivalcineindia2021/kika">
            <India2021Show id="616b590f7dcb600f4de4bb46" />
        </Route>
        <Route exact path="/festivalcineindia2021/hindimedium">
            <India2021Show id="616b590e7dcb600f4de4bb44" />
        </Route>
        <Route exact path="/festivalcineindia2021/bahubali">
            <India2021Show id="616b590f7dcb600f4de4bb48" />
        </Route>
        <Route exact path="/festivalcineindia2021/bahubali2">
            <India2021Show id="616b590f7dcb600f4de4bb4a" />
        </Route>
        <Route exact path="/festivalcineindia2021/neerja">
            <India2021Show id="616b59107dcb600f4de4bb4c" />
        </Route>
        <Route exact path="/festivalcineindia2021/queen">
            <India2021Show id="616c8aa57dcb600f4de4bb50" />
        </Route>

        {/* Soy Invencible */}
        <Route exact path="/soyinvencible">
            <InvencibleShow id="5fabf2947094a9f522057ae3" />
        </Route>
        <Route exact path="/soyinvencible/evento">
            <InvencibleShowPlayer id="5fabf2947094a9f522057ae3" />
        </Route>

        {/* Convivio Gallo */}
        <Route exact path="/conviviogallo">
            <ConvivioGalloShow id="5fbeee3974201ea1f0065132" />
        </Route>
        <Route exact path="/conviviogallo/20201128">
            <ConvivioGalloShowPlayer id="5fbeee3974201ea1f0065132" />
        </Route>
        {/* Convivio Dorada Ice */}
        <Route exact path="/conviviodoradaice">
            <ConvivioDoradaShow id="5fd1a3b32d682cbc40598f80" />
        </Route>
        <Route exact path="/conviviodoradaice/player">
            <ConvivioDoradaShowPlayer id="5fd1a3b32d682cbc40598f80" />
        </Route>

        {/* Festival de Comedia Guatemalteca */}
        <Route exact path="/festivalcomediaguate">
            <FestivalComediaMain id="5fc862ce3ee3efe594519a7b" />
        </Route>

        {/* Las Caras Del Amor */}
        <Route exact path="/lascarasdelamor">
            <CarasDelAmorMain id="5fc968b606ea49f23c9327db" />
        </Route>

        {/* Alux Nahual */}
        <Route exact path="/aluxnavidad2020">
            <AluxNavidad2020Main id="5fd97dc4efef01b70368a538" />
        </Route>
        <Route exact path="/aluxnavidad2020/player">
            <AluxNavidad2020ShowPlayer id="5fd97dc4efef01b70368a538" />
        </Route>

        {/* Titanes Summit 2021 */}
        <Route exact path="/titanessummit2021">
            <TitanesSummit2021Main id="6005e963eaf3235389ee7b20" />
        </Route>
        <Route exact path="/titanessummit2021/player">
            <TitanesSummit2021ShowPlayer id="6005e963eaf3235389ee7b20" />
        </Route>

        {/* Fafidess 2021 */}
        <Route exact path="/fafidess2021">
            <Fafidess2021Main id="609339cb6f75e09d25f4d53d" />
        </Route>
        <Route exact path="/fafidess2021/player">
            <Fafidess2021ShowPlayer id="609339cb6f75e09d25f4d53d" />
        </Route>

        {/* Guatemetal */}
        <Route exact path="/guatemetal">
            <GuatemetalMain id="6130639821324e63429bcfa9" />
        </Route>

        {/* Leyendas */}
        <Route exact path="/recorridodeleyendasgt">
            <LeyendasGtMain id="650a29fd7d155b8b8f3e39ce" />
        </Route>

        {/* Jandir */}
        <Route exact path="/jandirrodriguez">
            <JandirMain id="614e1d380ffb10805d96f43f" />
        </Route>
        <Route exact path="/jandirvolar">
            <JandirVolarMain id="621a4b43138d4b20ad89e9fc" />
        </Route>

        {/* Malas Intenciones */}
        <Route exact path="/malasintenciones">
            <MalasIntencionesMain id="61665333c58a1f2c513b199b" />
        </Route>

        {/* Marimbas */}
        <Route exact path="/ensamble-de-marimbas">
            <MarimbasPlayer id="61b2433e97f533a5758e1bef" />
        </Route>
        <Route exact path="/festival-de-marimbas">
            <Marimbas2Player id="669beda7e31bd87128c191b7" />
        </Route>

        {/* Teleton 2021 */}
        <Route exact path="/teleton2021">
            <Teleton2021Main id="61ba599d23b9823169747054" />
        </Route>

        {/* Cine de la India 2021 */}
        <Route exact path="/semanacineindia2023">
            <India2023Main id="63c35adcadbdfc4fbf4804b5" />
        </Route>
        <Route exact path="/semanacineindia2023/ankhon_dekhi">
            <India2023Show id="63c363d0adbdfc4fbf480520" />
        </Route>        
        <Route exact path="/semanacineindia2023/dedh_ishqiya">
            <India2023Show id="63c363d0adbdfc4fbf480523" />
        </Route>
        <Route exact path="/semanacineindia2023/john_day">
            <India2023Show id="63c363d0adbdfc4fbf480526" />
        </Route>
        <Route exact path="/semanacineindia2023/zed_plus">
            <India2023Show id="63c363d0adbdfc4fbf480529" />
        </Route>
        <Route exact path="/semanacineindia2023/8x10_tasveer">
            <India2023Show id="63c363d0adbdfc4fbf48052c" />
        </Route>
        <Route exact path="/semanacineindia2023/im_24">
            <India2023Show id="63c363d0adbdfc4fbf48052f" />
        </Route>
        <Route exact path="/semanacineindia2023/sharafat_gayi_tel_lene">
            <India2023Show id="63c363d0adbdfc4fbf480532" />
        </Route>        


        {/* Capitan Electrico */}
        <Route exact path="/capitan.electrico">
            <CapitanElectricoMain id="63f2cda973a39290ad41baa3" />
        </Route>

        {/* Viernes Verde */}
        <Route exact path="/viernesverde30">
            <ViernesVerdeMain id="642606dfcc03cb3bd09d3126" />
        </Route>
        <Route exact path="/viernesverde30occidente">
            <ViernesVerdeXelaMain id="651e0aaf994fcb4727dea0dd" />
        </Route>

        {/* Capitan Electrico */}
        <Route exact path="/despuesdelsilencio">
            <DespuesDelSilencioMain id="656ec4c91679d7d6e4b2fe23" />
        </Route>

        {/* Astro de Oro */}
        <Route exact path="/astrodeoro">
            <AstroDeOroMain id="6617698a5bf02a8759ac10f3" />
        </Route>

        {/* Seguridad Vial */}
        <Route exact path="/seminario-seguridad-vial">
            <SeguridadVialMain id="6635135aa3c41ba544676465" />
        </Route>
        <Route exact path="/seminario-seguridad-vial/player">
            <SeguridadVialPlayer id="6635135aa3c41ba544676465" />
        </Route>


      {/* Finally, catch all unmatched routes */}
        <Route>
            <NotFound />
        </Route>
    </Switch>
  );
}