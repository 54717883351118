const dev = {
  fingerprint: {
    ordId: '1snn5n9w',
    login: 'visanetgt_qpay',
  },
  apiGateway: {
    REGION: "us-east-1",
    // URL: "https://devapi.viveloonline.com"
    URL: "https://api.viveloonline.com"
  },  
  wsService: {
    url: "wss://x75aacsh9k.execute-api.us-east-1.amazonaws.com/dev"
  },
  ipgeolocation: {
    URL: 'https://api.ipgeolocation.io/',
    API_KEY: 'fe69de418f1f4476aed74f14dbfa5312'
  },
  firebase: {
    apiKey: 'AIzaSyBaAZqKn4cdbLlbQw2ags8VPA6F1CLlSGo',    
    databaseURL: 'https://vivelo-dev.firebaseio.com/'    
    // apiKey: 'AIzaSyCtAW_AQuaShfDi1WqnhfsOFIsO4fM7GAA',
    // databaseURL: 'https://vivelo-prod01.firebaseio.com/'
  },
  baseUrl: "http://demo02.viveloonline.com",
  analytics: {
    // trackingId: 'G-NWWG4S7284'
    trackingId: 'G-TEST'
  }
}
const prod = {
  fingerprint: {
    ordId: 'k8vif92e',
    login: 'rd2bf9x8cKDzR8GYZnKfxEd3',
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.viveloonline.com"
  },
  wsService: {
    //URL: "wss://ws.viveloonline.com/shows"
    url: "wss://e2llr85td3.execute-api.us-east-1.amazonaws.com/prod"
  },
  ipgeolocation: {
    URL: 'https://api.ipgeolocation.io/',
    API_KEY: 'fe69de418f1f4476aed74f14dbfa5312'
  },
  firebase: {
    apiKey: 'AIzaSyCtAW_AQuaShfDi1WqnhfsOFIsO4fM7GAA',
    databaseURL: 'https://vivelo-prod01.firebaseio.com/'
  },
  baseUrl: "https://viveloonline.com",
  analytics: {
    // trackingId: 'UA-170181956-1'
    trackingId: 'G-NWWG4S7284'
  }
}

const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  ...config
};