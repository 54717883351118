import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import 'moment/locale/es';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt, faPlus, faMoneyBillAlt, faInfoCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Form, Row, Col, Button, Badge, Modal } from "react-bootstrap";

import { useAnalytics } from "libs/analytics";
import { useCart } from "libs/cartLib";
import { useAppContext } from "libs/contextLib";
import { Notification } from "libs/errorLib";
import Moment from "react-moment";
// import { useOrder } from "libs/ordersLib";
// import './Loader.scss'

export default function ShowTickets(props) {
    const { show, viewerShow, notifyTicketChange, showAddToCart: showAddToCart = true, 
        quickBuyLink: quickBuyLink = false, ...otherProps } = props

    const history = useHistory();
    const [tickets, setTickets] = useState(null)
    const [totalTickets, setTotalTickets] = useState(0)
    const [orderTotal, setOrderTotal] = useState(0);
    const [orderDiscountTotal, setOrderDiscountTotal] = useState(0);
    const [currency, setCurrency] = useState('GTQ')


    const { trackEvent } = useAnalytics();
    // const [order, setOrder] = useOrder(show._id)
    const { getCart, addCartItems, removeCartItems } = useCart()
    const { updateCartCounter } = useAppContext()

    useEffect( () => {
        setTickets(props.tickets)        
        setOrderTotal(0)
        setTotalTickets(0)
        
        if (notifyTicketChange) {
            notifyTicketChange(null, 0, 0, currency)
        }
    }, [props.tickets])
    
    function applyPriceRule(t) {
        console.log('RULE',t)
        if (t.price_rules && t.price_rules.length > 0 && t.price_rules[0].rule_type == 'quantity_discount') {
            const rule = t.price_rules[0]
            if (t.selected >= rule.quantity_min) {
                const discount_price = rule.item_discount_price
                const subtotal_price = discount_price > 0 ? t.selected * discount_price : 0
                return subtotal_price
            }
        }
        return 0
    }

    function handleTicketSelection(event, id) {
        let total = 0
        let discount_total = 0
        let count = 0
        let currency = 'Q'
        let updated = tickets.map(t => {            
            if (t._id === id) {
                t.selected = event.target.value
            }

            total += t.price * t.selected
            count += t.selected
            currency = t.currency || show?.settings?.currency || 'GTQ'

            discount_total = applyPriceRule(t)
            
            return t
        })        
        setTickets(updated)
        setOrderTotal(total)
        setOrderDiscountTotal(discount_total)
        setTotalTickets(count)
        setCurrency(currency)

        if (notifyTicketChange) {
            notifyTicketChange(updated, count, total, discount_total, currency)
        }
    } 

    function validateOrderForm() {
        return totalTickets > 0
    }

    async function handleAddToCartButton() {
        registerCartItem(true)
    }

    async function handlePurchaseButton(addToCartOnly) {
        registerCartItem(false)
    }

    async function registerCartItem(addToCartOnly) {
        if (addToCartOnly) {
            trackEvent({
                action: "Clicked Add to Cart Button",
                category: "PurchaseFunnel"
            });
        } else {
            trackEvent({
                action: "Clicked Buy Now Button",
                category: "PurchaseFunnel"
            });
        }

        const currency = show?.settings?.currency || 'GTQ'

        const items = tickets.filter(t => parseInt(t.selected) > 0 ).map(t => {
            const date = moment(show.show_date).format('ddd, LL - LT')

            const cartItem = {
                ticket_uid: t._id,
                type: 'TICKET',                
                show_uid: show._id,
                show_name: show.fullname,
                show_date: show.show_date,
                show_type: show.type,
                venue: show.venue,                
                title: `${show.fullname} (${date})`,
                description: t.fullname,
                note: t.note,
                ticket_type: t.type,
                image: `${show.main_image}`,
                quantity: parseInt(t.selected),                
            }
            console.log('items', t.bundle)
            if (t.bundle && t.bundle.length > 0) {
                cartItem.bundle_items = t.bundle.map(item => {
                    const it = {
                        ticket_uid: item._id,
                        code: item.code,
                        type: 'TICKET',
                        show_uid: show._id,
                        show_name: show.fullname,
                        show_date: show.show_date,
                        show_type: show.type,
                        venue: show.venue,
                        description: item.fullname,
                        note: item.note,
                        ticket_type: item.type,
                        image: `${show.main_image}`,
                        quantity: item.ammount * parseInt(t.selected)
                    }
                    if (item.location) {
                        it.location = item.location
                    }
                    if (item.ticket_image) {
                        it.ticket_image = item.ticket_image
                    }
                    return it
                })
            }
            if (t.location) {
                cartItem.location = t.location
            } else {
                cartItem.location = {}
            }

            return cartItem
        })
        try {
            await addCartItems({ currency: currency, items: items })
            updateCartCounter()
            if (addToCartOnly) {
                history.push('/cart',{})
            } else {
                history.push('/cart/checkout')
            }
        } catch(e) {
            if (e.response.status == 400) {                
                Notification('error', 'No fue posible agregar los boletos al carrito')
                history.push('/cart',{})
            } else {
                Notification('error', 'No fue posible agregar los boletos al carrito')
                history.push('/cart',{})
            }
        }
        
    }

    function renderTickets() {
        // <Col md="2">{!viewerShow ? <Button variant="primary" data-ticket-id={t.id} onClick="">Comprar</Button> : null }</Col>
        const now = moment.utc()

        let items = [];
        if (tickets) {
            items = tickets.filter( t => {
                    return t.visible
                }).map( t => {
                    const since = moment.utc(t.valid_since);
                    const thru = t.valid_thru ? moment.utc(t.valid_thru) : now

                    t.since = since
                    t.thru = thru
                    t.expired = now.isAfter(thru)
                    t.can_buy = now.isSameOrAfter(since)
                    return t
                })
                .map( t => {
                    let options = []
                    for(let i = 0; i <= 10 && i <= t.real_available; i++) {
                        options.push(<option value={`${i}`} key={`${t.ticket_id}-${i}`}>{`${i}`}</option>)
                    }

                    let buy = ''
                    if (t.expired) {
                        buy = <span><Badge variant="secondary">No Disponible</Badge></span>
                    } else if (t.can_buy) {
                        buy = t.real_available > 0 
                            ? <Form.Control as="select" value={t.selected} onChange={(e) =>handleTicketSelection(e, t._id)} >
                                { options }                              
                            </Form.Control>
                            : <Badge variant="secondary">AGOTADOS</Badge>                        
                    } else {
                        buy = <span className="presale-date">A partir del <Moment local format="lll">{t.since}</Moment></span>
                        // buy = <span><Badge variant="success">Próximamente</Badge></span>
                    }

                    return <Row key={t._id} style={{marginTop: 10}} className="ticket">
                        <Col xs={8} md={6} className="ticket-name" >{t.fullname}{t.note ? <div className="note">{t.note}</div> : null }</Col>
                        <Col xs={4} md={3} className="price">{t.currency == 'USD' ? t.price_usd_text : t.price_text}</Col>                    
                        <Col xs={12} md={3} className="ticket-selector">
                            { buy }
                        </Col>
                    </Row>
                    
                })
        } else {         
            items.push( <Row>
                <Col><Badge variant="info" style={{fontWeight:'normal'}}>Pr&oacute;ximamente</Badge></Col>                                
            </Row> )
        }
        const buyText = viewerShow ? "Comprar más" : "Comprar"
        const showTickets = ['ACTIVE','STARTED','LIVE','REPLAY','HIDDEN','TEST'].includes(show.status);

        if (['NEW','CLOSED'].includes(show.status)) {
            return null;
        }

        return showTickets && tickets && tickets.length > 0 ? <><section id="tickets" ><label>Boletos</label></section>
             {items}
                <Row style={{borderTop: 'solid 2px #666', marginTop: 10, paddingTop: 10, textAlign:'right'}} className="tickets-addtocart">
                    {/* <Col md="12" > */}
                    { showAddToCart && <Button variant={props.addToCartButtonVariant || "info"} style={{width:200}}                          
                        disabled={!validateOrderForm()}
                        onClick={handleAddToCartButton}><FontAwesomeIcon icon={faPlus} /> Agregar al Carrito</Button> }
                    {/* </Col> */}
                </Row>
                {/* <Row style={{borderTop: 'solid 2px #666', paddingTop: 10, textAlign:'right'}}>
                    <Col md="12" className="col-5 "><Button variant={props.purchaseButtonVariant || "danger"} style={{width:200}}  
                        disabled={!validateOrderForm()}
                        onClick={handlePurchaseButton}><FontAwesomeIcon icon={faTicketAlt} /> Comprar Ahora</Button></Col>
                </Row> */}
                
                <Row className="tickets-total">
                    <Col xs={6} md={6} className="tickets-total-label">TOTAL</Col>
                    <Col xs={6} md={6} className="price">{renderTotal()}</Col>
                    {/* <Col xs={12} md={3}></Col> */}
                </Row>
                <Row className="tickets-footer"><Col className="note">**Recibirás un correo por cada boleto en el correo electrónico registrado en el siguiente formulario</Col></Row>
                
                {/* : <Row><Col><Badge variant="info" style={{fontWeight:'normal'}}>Pr&oacute;ximamente</Badge></Col></Row> } */}
            </> : <></>

    }

    function renderTotal() {
        if (orderDiscountTotal > 0) {
            return <><span style={{textDecoration:'line-through'}}>{currency} {orderTotal}</span> {currency} {orderDiscountTotal}</>
        } else {
            return <>{currency} {orderTotal}</>
        }
    }

    function renderQuickBuyLink() {
        return <div class="quickbuy_link">
            <a href={show.settings.quickbuy_link} target="_blank">                
                <FontAwesomeIcon icon={faMoneyBillAlt} />
                <span>Registra aquí tu pago con depósito o transferencia bancaria<br />{show.settings.quickbuy_link}</span>                
            </a>
        </div>
    }

    function render() {        
        if (quickBuyLink) {
            return renderQuickBuyLink()
        } else {
            return renderTickets()
        }
    }
    return render()
}