import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import Cookie from 'js-cookie';
import 'moment/locale/es';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt, faPlus, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Form, Row, Col, Alert } from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser'; 

import FileUploader from "components/FileUploader";
import { LinkContainer } from "react-router-bootstrap";

import LoaderButton from "components/LoaderButton";
import Loader from "components/Loader"

import ShowTitle from "components/shows/ShowTitle";
import ShowImage from "components/shows/ShowImage";
import ShowTickets from "components/shows/ShowTickets";
import ShowMapTickets from "components/shows/ShowMapTickets";
import ShowDates from "components/shows/ShowDates";
import CartSummaryBox from "components/cart/CartSummaryBox"  
import CartTimer from "components/cart/CartTimer";


import { useCart } from "libs/cartLib";
import { useAnalytics } from "libs/analytics";
import { useFormFields } from "libs/hooksLib";
import { useAppContext } from "libs/contextLib";
import { renderCountryOptions } from "libs/formsLib";
import { onError, Notification } from "libs/errorLib";
// import { useRecaptcha } from "libs/recaptchLib";

import "./QuickBuyForm.css";

export default function QuickBuyForm({user, sessionId, show, tickets, showMap, showDates, 
    onShowDateChange, selectedShowDate, bankDepositInfo, isLoadingData,
    ...props}) {
    
    const history = useHistory();

    const [selectedFile, setSelectedFile] = useState(null)

    const [isLoading, setIsLoading] = useState(false)
    const [orderTotal, setOrderTotal] = useState(0)
    // const [cart, setCart] = useState(null)
    const [selectedTickets, setSelectedTickets] = useState(null)
    const [totalTickets, setTotalTickets] = useState(0)    

    const { trackEvent } = useAnalytics();
    const { getSession, cart, cartTotalItems } = useAppContext();
    const { getCart, createOrder, resetCart, addCartItems, getTotalPrice } = useCart();    
    
    const [fields, handleFieldChange] = useFormFields({
        saveInfo: true,
        firstname: user?.firstname || "",
        lastname: user?.lastname || "",
        email: user?.email || "",
        emailConfirm: user?.email || "",
        phone: user?.phone || "",
        address: user?.address || "Ciudad",
        city: user?.city || "Guatemala",
        state: user?.state || "Guatemala",
        country: user?.country || "GT",
        zip: user?.zip || "",
        nit: user?.nit || "",
        reference: "",        
        bankName: ""    
      });

    // const { initRecaptcha, executeRecaptcha } = useRecaptcha();
    

    // useEffect(() => {

    //     // const searchParams = new URLSearchParams(location.search);
    //     // if (searchParams.get('activated') == "1") {
    //     //     //Notification('success','Tu boleto ha sido activado exitosamente')
    //     //     setShowActivationModal(false)        
    //     // }
    
    //     async function onLoad() {
    //         try {           
    //             setIsLoading(true)
    //             session = await getSession()
                                
    //             // if (isAuthenticated) {    
    //             //     console.log(session, show)            
    //             //     const vShow = await loadViewerShow(session.viewer._id, show._id)
    //             // }
    
    //         } catch (e) {
    //             onError(e);
    //         } finally {
    //             setIsLoading(false)
    //         }
    //     }
    
    //     onLoad();
    // }, []);  
    useEffect(() => {        
        onLoad()         
        
    }, [show]);

    useEffect(() => {   
        console.log(selectedShowDate)     
        handleNotifyTicketChange(null, 0, 0, 'GTQ') 
        
    }, [selectedShowDate]);

    // useEffect(() => {   
    //     // console.log(selectedShowDate)     
    //     // handleChangeOrderButton(null, 0, 0, 'GTQ') 
        
    // }, [totalPrice, totalTickets]);

    async function onLoad() {
        // let cart
        // try {
        //     cart = await getCart()
        //     setCart(cart || {})
        // } catch (e) {
        //     // no cart
        // }
    }

    useEffect(() => {
        console.log('selectedFile', selectedFile)

    }, [selectedFile]);

    // useEffect(() => {
    //     // initRecaptcha()
    // }, []);

    useEffect(() => {
        console.log('cart updated. Total items ' + cartTotalItems)
        setTotalTickets(cartTotalItems)
        const orderTotal = getTotalPrice(cart, true)
        console.log('ordertotal ' + orderTotal)
        setOrderTotal(orderTotal)
    }, [cartTotalItems]);

    async function processPayment(currency, payment_type, payment_info) {
        const session = await getSession()

        const order = {
            user_uid: user?._id,
            payment_type: payment_type,
            currency: currency,
            payment_info: payment_info,                
        }
        const purchase = await createOrder(order)

        // const purchase = await API.post("vivelo", `/viewers/orders/`, {
        //     body: {
        //         user_uid: session.user._id,
        //         payment_type: payment_type,
        //         currency: 'GTQ',
        //         payment_info: payment_info,                
        //     }
        // });
        
        return purchase
    }



    /* VALIDATORS */
    function validateForm() {
        
        return fields.firstname.length > 0 
            && fields.lastname.length > 0
            && fields.email.length > 0
            && fields.email == fields.emailConfirm
            && fields.phone.length > 0 
            && fields.address.length > 0 
            && fields.city.length > 0 
            && fields.state.length > 0 
            && fields.country.length > 0 
            && ((selectedShowDate.settings?.ask_for_nit && fields.nit.length > 0) || !selectedShowDate.settings?.ask_for_nit)
            // && (
            //     (['GT'].includes(fields.country) && fields.nit.length > 0) ||
            //     (['US','CA'].includes(fields.country) && fields.zip.length > 0) ||
            //     (!['GT','US','CA'].includes(fields.country))
            // )
            // && fields.reference.length > 0 
            // && fields.bankName
            // && orderTotal > 0;

    }

    /* HANDLERS */
    function handleChangeOrderButton() {
        trackEvent({
            action: "Clicked ChangeOrder Button",
            category: "PurchaseFunnel"
        });
    }            

    async function handleSubmit(event) {    
        event.preventDefault();        
        setIsLoading(true);
        trackEvent({
            action: "Clicked Pay Button",
            category: "PurchaseFunnel"
        });        
        
        try {
            const currency = 'GTQ'
            let payment_type = 'BANK_DEPOSIT'

            const payment_info = {
                firstname: fields.firstname,
                lastname: fields.lastname,
                email: fields.email,
                phone: fields.phone,
                address: fields.address,
                // city: fields.city,
                // state: fields.state,
                country: fields.country,
                nit: fields.nit || "",
                
                reference: fields.reference,
                file_info: selectedFile.fileUrl,
                currency: currency || 'GTQ',
                total: orderTotal,
                sessionId: Cookie.get('sessionID')
        }

            if (!showMap) {
                try {                
                    await resetCart()
                    
                } catch(e) {console.log('error resetting')}

                

                
                

                const items = selectedTickets.filter(t => parseInt(t.selected) > 0 ).map(t => {            
                    const date = moment(selectedShowDate.show_date).format('ddd, LL - LT')
                    
                    if (t.payment_type.includes('ACCOUNT_BANK_DEPOSIT')) {
                        payment_type = 'ACCOUNT_BANK_DEPOSIT'
                    } else if (t.payment_type.includes('BANK_DEPOSIT')) {
                        payment_type = 'BANK_DEPOSIT'
                    }

                    const cartItem = {
                        ticket_uid: t._id,
                        code: t.code,
                        type: 'TICKET',
                        show_uid: selectedShowDate._id,
                        show_name: selectedShowDate.fullname,
                        show_date: selectedShowDate.show_date,
                        show_type: selectedShowDate.type,
                        venue: selectedShowDate.venue,
                        title: `${selectedShowDate.fullname} (${date})`,
                        description: t.fullname,
                        note: t.note,
                        ticket_type: t.type,
                        image: `${selectedShowDate.main_image}`,
                        quantity: parseInt(t.selected)
                    }

                    if (t.bundle && t.bundle.length > 0) {
                        cartItem.bundle_items = t.bundle.map(item => {
                            const it = {
                                ticket_uid: item._id,
                                code: item.code,
                                type: 'TICKET',
                                show_uid: selectedShowDate._id,
                                show_name: selectedShowDate.fullname,
                                show_date: selectedShowDate.show_date,
                                show_type: selectedShowDate.type,                               
                                venue: selectedShowDate.venue,
                                description: item.fullname,
                                note: item.note,
                                ticket_type: item.type,
                                image: `${selectedShowDate.main_image}`,
                                quantity: item.ammount * parseInt(t.selected)
                            }
                            if (item.location) {
                                it.location = item.location
                            }
                            return it
                        })
                    }

                    if (t.location) {
                        cartItem.location = t.location
                    }

                    return cartItem
                })
                await addCartItems({ payment_type: payment_type, currency: currency, items: items })
                    
            } else {
                if (show?.settings?.deposit_payment_type?.includes('ACCOUNT_BANK_DEPOSIT')) {
                    payment_type = 'ACCOUNT_BANK_DEPOSIT'
                } else if (show?.settings?.deposit_payment_type?.includes('BANK_DEPOSIT')) {
                    payment_type = 'BANK_DEPOSIT'
                }
            }
            const purchase = await processPayment(currency, payment_type, payment_info)
            if (purchase) {
                // console.log(purchase)
                //setIsLoading(false);
                payment_info.save_info = fields.saveInfo
                props.onSuccess && props.onSuccess(payment_info, purchase)                
                return true
            } else {
                Notification('error', 'No fue posible realizar la compra', null, 'top')
                props.onFailure && props.onFailure(purchase)
                setIsLoading(false);
                return false
            }        
            
        } catch(error) {
            console.log(error, error.response, error.status, error.data, error.errors)
            if (error.response && error.response.status === 402) {
                switch(error.response.data.code) {
                    case 31: {
                        Notification('error', 'La transacción no pudo ser completada. Tarjeta no soportada por SWITCH', null, 'top')
                        break;
                    }
                    case 58: {
                        Notification('error', 'La transacción no pudo ser completada, por favor intenta nuevamente. ', null, 'top')
                        break;
                    }
                    case 203: {
                        Notification('error', 'Transacción rechazada por el banco emisor. Por favor revisa los datos ingresados o contacta a tu banco para autorizar la transacción', null, 'top')
                        break;
                    }
                    case 481: {
                        Notification('error', 'Transacción rechazada por el sistema antifraude. Por favor revisa los datos e intenta de nuevo en 24 horas.', null, 'top')                
                        break;
                    }
                    default: {
                        Notification('error', 'La transacción ha sido denegada.  Revisa que todos los datos esten correctos.', null, 'top')                
                        break;
                    }
                }                
                setIsLoading(false);
            } else {
                Notification('error', 'No fue posible realizar la compra', null, 'top')
                setIsLoading(false);
            }
            return false
        } finally {
            
        }

        
    }



    function handleNotifyTicketChange(tickets, totalTickets, orderTotal, orderDiscountTotal, currency) {
        setSelectedTickets(tickets)
        setTotalTickets(totalTickets)
        setOrderTotal(orderTotal)
    }

    function renderShowTickets() {
        if (selectedShowDate) {
            if (tickets && tickets.length > 0) {
                // return <ShowTickets show={selectedShowDate} tickets={tickets} showAddToCart={false} notifyTicketChange={handleNotifyTicketChange} />
                return selectedShowDate?.settings?.has_map ?
                        showMap ? 
                        <div style={{marginTop:10}}>
                            <ShowMapTickets show={selectedShowDate} showTickets={tickets} showMap={showMap} paymentType={"DEPOSIT"} showViewCart={false}  />
                            <CartTimer />
                            <CartSummaryBox readOnly={true} />
                        </div> : <Loader /> 
                    : <ShowTickets show={selectedShowDate} tickets={tickets} showAddToCart={false} notifyTicketChange={handleNotifyTicketChange}  />
                
            } else {
                return <div style={{paddingTop: 10}}>
                { isLoadingData 
                    ? <Loader />
                    : <Alert variant="warning">No hay tickets disponibles para depósito o transferencia en este evento</Alert> 
                }
                </div>
            }
            
        }
        return <></>
    }

    function renderForm() {        
        return <div className="quicklink">
            <Form onSubmit={handleSubmit} className="show-form">
            <Row>
                <Col md="6">                    
                    
                    <div>
                        <ShowImage show={show} />
                        <ShowTitle show={show} />
                        { showDates && <ShowDates show={show} showDates={showDates} onChange={onShowDateChange} /> }
                    </div>   
                    <div>                        
                        { renderShowTickets() }
                    </div>                    
                    { orderTotal > 0 && totalTickets > 0 && <>
                        <h2 style={{marginTop: 20}}>Depósito o Transferencia</h2>
                    <Form.Row >
                        <Form.Group as={Col} controlId="file">
                        <div className="deposit-info">{ReactHtmlParser(bankDepositInfo || "")}</div>                                                
                        </Form.Group>                        
                    </Form.Row>
                    <Form.Row style={{justifyContent: 'center'}} key="fileupload">
                        <FileUploader setSelectedFile={setSelectedFile} uploadText="Adjunta tu comprobante de depósito o transferencia"
                            show_uid={show._id} session_id={sessionId} />
                    </Form.Row>                    

                    <h2 style={{marginTop: 20}}>Datos de personales</h2>
                    <Form.Row>
                        <Form.Group as={Col} controlId="saveInfo">                        
                        <Form.Check type="checkbox" label="Guardar los datos en mi perfil" checked={fields.saveInfo} onChange={handleFieldChange} />
                        </Form.Group>
                        
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="firstname">
                        <Form.Label>Nombre*</Form.Label>
                        <Form.Control required placeholder="ej: José"  value={fields.firstname} onChange={handleFieldChange} className={fields.firstname == "" ? "field-error" : "field-ok"} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="lastname">
                        <Form.Label>Apellido*</Form.Label>
                        <Form.Control placeholder="ej: Pérez"  value={fields.lastname} onChange={handleFieldChange}  className={fields.lastname == "" ? "field-error" : "field-ok"} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="email">
                        <Form.Label>Email*</Form.Label>
                        <Form.Control type="email" placeholder="" value={fields.email} onChange={handleFieldChange}  className={fields.email == "" ? "field-error" : "field-ok"} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="emailConfirm">
                        <Form.Label>Confirmar Email*</Form.Label>
                        <Form.Control type="email" placeholder="" value={fields.emailConfirm} onChange={handleFieldChange}  className={fields.emailConfirm != fields.email ? "field-error" : "field-ok"} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} xs={6} controlId="phone">
                        <Form.Label>Telefono*</Form.Label>
                        <Form.Control type="tel" placeholder="" value={fields.phone} onChange={handleFieldChange}  className={fields.phone == "" ? "field-error" : "field-ok"} />
                        </Form.Group>

                    { selectedShowDate.settings?.ask_for_nit && <>
                        <Form.Group as={Col} xs={6} controlId="nit">
                        <Form.Label>NIT <span style={{fontSize: '1rem'}}>("C/F" si no tienes NIT)</span></Form.Label>
                        <Form.Control value={fields.nit} onChange={handleFieldChange}  className={fields.nit == "" ? "field-error" : "field-ok"} />
                        </Form.Group>
                        </> }
                    </Form.Row>

                    <Form.Row>
                        <LoaderButton
                            block
                            type="submit"
                            size="lg"
                            isLoading={isLoading}
                            disabled={!validateForm()}
                            variant={validateForm() ? 'primary' : 'secondary'}                            
                        >
                        Registrar Pago
                        </LoaderButton>                        
                        <div className="note" style={{fontFamily: 'Arial'}}><FontAwesomeIcon icon={faInfoCircle} /> Este pago se está haciendo directamente al organizador del evento. Vívelo actúa solamente como facilitador del medio de pago y la boletería. Cualquier duda o reclamo, contactar directamente el organizador del evento.</div>
                    </Form.Row>

                    </> }


                </Col>                
            </Row>                

                
            </Form>
        </div>           
    }

    return renderForm()
}